import React from 'react';
import { Carousel, Typography } from '@material-tailwind/react';

import SeasideResidence from '../img/seaside_residences.jpg';
import AsiaSquare from '../img/asia_square.webp';
import NTUHive from '../img/ntu_hive.jpg';
import OutramCommHospital from '../img/outram_community_hospital.jpg';

export function ProjectsCarousel() {
  return (
    <Carousel
      className="rounded-xl"
      autoplay={true}
      transition={{ type: 'tween', duration: 0.5 }}
      autoplayDelay={5000}
      loop={true}>
      <div className="relative h-full w-full">
        <img src={AsiaSquare} alt="image 1" className="h-full w-full object-cover" />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-5xl">
              Commercial
            </Typography>
            <Typography variant="lead" color="white" className="mb-12 opacity-80">
              Asia Square Tower
              <br />
              NTT Data Centre
              <br />
              Microsoft SG2
              <br />
              OUB Building
              <br />
              Vision Exchange
              <br />
              Seagate
            </Typography>
          </div>
        </div>
      </div>

      <div className="relative h-full w-full">
        <img src={OutramCommHospital} alt="image 2" className="h-full w-full object-cover" />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-5xl">
              Infrastructure
            </Typography>
            <Typography variant="lead" color="white" className="mb-12 opacity-80">
              Changi Prison Clusters
              <br />
              Ministry of Manpower
              <br />
              Outram Community Hospital
              <br />
              Sengkang General Hospital
              <br />
              Tuas Power Station
              <br />
              Fort Canning Arts Centre
              <br />
              Tampines Town Hub
            </Typography>
          </div>
        </div>
      </div>

      <div className="relative h-full w-full">
        <img src={SeasideResidence} alt="image 2" className="h-full w-full object-cover" />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-5xl">
              Residential
            </Typography>
            <Typography variant="lead" color="white" className="mb-12 opacity-80">
              Parc Esta
              <br />
              Seaside Residence
              <br />
              Park Colonial
              <br />
              Irwell Hill Residence
              <br />
              Lush Acres EC
              <br />
              Park Colonial
            </Typography>
          </div>
        </div>
      </div>

      <div className="relative h-full w-full">
        <img src={NTUHive} alt="image 2" className="h-full w-full object-cover" />
        <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/75">
          <div className="w-3/4 text-center md:w-2/4">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-5xl">
              Schools
            </Typography>
            <Typography variant="lead" color="white" className="mb-12 opacity-80">
              NTU Hall Residence
              <br />
              National University of Singapore
              <br />
              SMUL
              <br />
              GEMS World Academy
              <br />
              Singapore American School
            </Typography>
          </div>
        </div>
      </div>
    </Carousel>
  );
}

export default ProjectsCarousel;
