import { Typography } from '@material-tailwind/react';
import React, { useState } from 'react';
import { AiFillMessage } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import ReactGA from 'react-ga4';

const FloatingButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const updateAnalytics = () => {
    ReactGA.event({
      category: 'Feature Usage',
      action: 'Floating Chatbox Open'
    });
  };

  return (
    <div className="fixed bottom-10 right-5 transition-all">
      {isOpen ? (
        <div>
          {/* Top cap */}
          <div className="bg-gradient-to-r from-cyan-500 to-blue-500 rounded-t-md h-[8vh] lg:w-[25vw] md:w-[40vw] w-[70vw] flex flex-col">
            <Typography className="m-auto font-light text-xl text-white">Laser Ace</Typography>
            <div className="absolute right-2 top-2">
              <button
                onClick={() => {
                  setIsOpen(false);
                  updateAnalytics();
                }}>
                <IoMdClose size="2rem" color="white" />
              </button>
            </div>
          </div>
          <div className="bg-gray-100 lg:w-[25vw] md:w-[40vw] w-[70vw] rounded-b-md pb-10 border-2 border-gray-300">
            {/* Main section */}
            <div className="flex flex-col px-6">
              <Typography className="font-medium text-justify pt-4 mb-4">
                Good day! You may contact us via any of the following means:
              </Typography>
              <div className="flex flex-col px-4 gap-2 rounded-lg bg-white py-6 text-xl">
                <a href={'tel:+6562596233'}>
                  <Typography className="flex flex-row items-center gap-3 font-medium">
                    <FaPhoneAlt />
                    (65) 6259 6233
                  </Typography>
                </a>
                <a href={'mailto:sales@laser-ace.com'}>
                  <Typography className="flex flex-row items-center gap-3 font-medium">
                    <MdOutlineEmail />
                    sales@laser-ace.com
                  </Typography>
                </a>
                <a href={'https://wa.me/6597116974'}>
                  <Typography className="flex flex-row items-center gap-3 font-medium">
                    <FaWhatsapp />
                    Click to chat on WhatsApp
                  </Typography>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <button onClick={() => setIsOpen(true)}>
          <div className="flex justify-center items-center rounded-full z-50 bg-blue-300 lg:h-[5vw] lg:w-[5vw] md:h-[10vw] md:w-[10vw] h-[15vw] w-[15vw] max-w-[70px] max-h-[70px]">
            <AiFillMessage className="w-1/2 h-1/2" color="white" />
          </div>
        </button>
      )}
    </div>
  );
};

export default FloatingButton;
