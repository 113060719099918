import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-primary h-[15vh] flex bg-cable6 bg-cover">
      <div className="m-auto">
        <p className="text-white text-center px-2">
          Copyright &copy; {new Date().getFullYear()}. Laser Ace Cable and Accessories Pte Ltd. All
          Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
