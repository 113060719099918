import { Typography } from '@material-tailwind/react';
import React from 'react';
import { Link } from 'react-router-dom';

const ProductHomeCard = ({ product }) => {
  console.log(product);
  // destructure product
  const { id, title } = product;
  return (
    <div className="w-full">
      <Link to={`/product/${id}`}>
        <div className="lg:h-[120px] h-[80px] relative overflow-hidden group transition border border-logodarkgreen border-2">
          <div className="w-full h-full flex flex-col justify-between items-center bg-logogreen hover:pb-2 px-2">
            {/* <div className="w-[320px] h-[200px] group-hover:scale-110 transition duration-300 mx-auto flex justify-center items-center p-2 bg-white rounded-3xl">
              <div className="absolute inset-0 grid h-full w-full place-items-center bg-logoblue"> */}
            <div className="text-center lg:pt-8 pt-4">
              <Typography color="white" variant="h6">
                {title}
              </Typography>
            </div>
            <hr className="border-logoblue w-32 mb-8 mt-1" />
            {/* </div>
            </div> */}
          </div>
        </div>
      </Link>

      {/* category, title */}
      {/* <div>
        <Link to={`/product/${id}`}>
          <h2 className="text-center font-semibold mb-1">{name}</h2>
        </Link>
      </div> */}
    </div>
  );
};

export default ProductHomeCard;
