import React, { useContext, useState } from 'react';
import { Card, Typography, Alert } from '@material-tailwind/react';

import { ProductContext } from '../contexts/ProductContext';

const AdminEditComponent = ({ userKey }) => {
  const { products, fetchProducts } = useContext(ProductContext);

  const [productForm, setProductForm] = useState({});

  const [message, setMessage] = useState('');

  let product_headers = [];
  if (products.length > 0) {
    product_headers = Object.keys(products[0]);
    product_headers.push('');
  }

  const deleteProduct = async (id) => {
    setMessage(`Deleting product with id ${id}...`);
    const res = await fetch('/api/?q=delete_product', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        key: userKey,
        id: id
      })
    });
    const res_body = await res.json();
    if (res_body === 'true') {
      fetchProducts();
      setMessage(`✅ Deletion of product with id ${id} successful!`);
    } else {
      setMessage('❌ Failed to delete');
    }
  };

  const addProduct = async (key, params) => {
    setMessage(`Adding product ${JSON.stringify(params)}...`);
    const fullBody = Object.assign({}, { key }, params);
    const res = await fetch('/api/?q=add_product', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(fullBody)
    });
    const res_body = await res.json();
    if (res_body === 'true') {
      fetchProducts();
      setMessage(`✅ Addition of product successful!`);
      setProductForm({});
    } else {
      setMessage('❌ Failed to add');
    }
  };

  return (
    <div className="w-screen lg:px-6 px-4">
      {message ? <Alert variant="ghost">{message}</Alert> : ''}
      <div className="lg:mt-20 mt-10">
        Products
        <Card className="h-full w-full overflow-scroll">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {product_headers.map((header, idx) => (
                  <th
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 lg:w-[12vw] w-20"
                    key={idx}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70">
                      {header}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  {Object.values(product).map((value, idx) => (
                    <td className="p-4 border-b border-blue-gray-50 lg:max-w-36 max-w-20" key={idx}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal break-words">
                        {value}
                      </Typography>
                    </td>
                  ))}
                  <td className="p-4">
                    <Typography
                      as="a"
                      href="#"
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                      onClick={() => deleteProduct(product.id)}>
                      Remove
                    </Typography>
                  </td>
                </tr>
              ))}
              {/* Add data */}
              <tr onChange={(event) => console.log(event)}>
                {product_headers.slice(0, product_headers.length - 1).map((field, idx) => (
                  <td className="p-4 border-b border-blue-gray-50" key={idx}>
                    <div>
                      <input
                        placeholder={field}
                        color="blue-gray"
                        className="font-normal border w-full disabled:bg-gray-600"
                        name={field}
                        onChange={(e) =>
                          setProductForm({ ...productForm, [field]: e.target.value })
                        }
                        value={productForm[field] ? productForm[field] : ''}
                        disabled={idx == 0 ? true : false}
                      />
                    </div>
                  </td>
                ))}
                <td className="p-4">
                  <Typography
                    as="a"
                    href="#"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                    onClick={() => addProduct(userKey, productForm)}>
                    Add
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

export default AdminEditComponent;
