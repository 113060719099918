import React from 'react';
import { Typography } from '@material-tailwind/react';

const HomepageSection = ({ title, tag, children, background, textColor }) => (
  <div
    id={tag}
    className={`scroll-mt-[10vh] lg:py-16 py-8 ${background ? background : 'bg-white'}`}>
    <Typography
      variant="h1"
      className="text-center col-span-1 lg:col-span-2 py-4"
      color={textColor ? textColor : 'gray'}>
      {title}
    </Typography>
    {children}
  </div>
);

export default HomepageSection;
