import React, { useContext } from 'react';
import Hero from '../components/Hero';
import { Typography } from '@material-tailwind/react';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import { RiMapPin2Fill } from 'react-icons/ri';
import { ProductContext } from '../contexts/ProductContext';

import ProductBackground from '../img/product_background.png';

import LaserAceLogo from '../img/laserace_dark.png';
import EuroflexLogo from '../img/euroflex_logo.jpg';
import EurotronicLogo from '../img/eurotronic_logo.jpg';
import OctLogo from '../img/oct_logo.png';

import Cert1 from '../img/cert1.jpg';
import Cert2 from '../img/cert2.gif';
import Cert3 from '../img/cert3.jpg';
import Cert4 from '../img/cert4.png';
import Cert5 from '../img/cert5.jpg';
import Cert6 from '../img/cert6.png';
import Cert7 from '../img/cert7.gif';
import Cert8 from '../img/cert8.png';
import Cert9 from '../img/cert9.jpg';

import HomepageSection from '../components/HomepageSection';
import ProductCategory from '../components/ProductHomeCard';
import { ProjectsCarousel } from '../components/ProjectsCarousel';

const Home = () => {
  const { products } = useContext(ProductContext);

  // [Image Import, Alt Text]
  const certificationData = [
    [Cert1, 'UL/cUL Certification'],
    [Cert2, 'UR/RU'],
    [Cert3, 'ETL Certification'],
    [Cert4, 'Delta Certificate'],
    [Cert5, 'RoHS Compliant'],
    [Cert6, 'VDE Mark'],
    [Cert7, 'CE Marking'],
    [Cert8, 'BASEC HAR Scheme'],
    [Cert9, 'TÜV SÜD PSB']
  ];
  const certifications = certificationData.map((item, index) => (
    <img
      src={item[0]}
      alt={item[1]}
      className="bg-white rounded-lg p-6 lg:max-h-40 max-h-28 object-contain"
      key={index}
    />
  ));

  return (
    <div id="home" className="min-h-[85vh] pt-[12vh]">
      <Hero />
      <section>
        {/* Section - General About */}
        <div id="about" className="scroll-mt-[10vh] w-screen lg:border-b-2 border-neutral-300">
          <div className="h-s grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
            <div className="grid grid-cols-3 bg-secondary lg: py-10 px-4 items-center">
              <img src={LaserAceLogo} alt="Laser Ace Logo" className="justify-self-center" />

              <div className="col-span-2 text-center lg:text-left">
                <Typography className="font-extrabold text-lg" variant="h1">
                  Laser Ace Cable And Accessories Pte Ltd
                </Typography>

                <Typography className="pt-4 font-light text-xs">Subsidiary Company</Typography>
                <Typography className="font-semibold">
                  Laser Ace Datacom Technology Pte Ltd
                </Typography>

                <Typography className="pt-4 font-light text-xs">Contact</Typography>
                <div className="flex lg:flex-row flex-col flex-wrap items-center lg:gap-4 gap-1">
                  <a href={'tel:+6562596233'}>
                    <Typography className="flex flex-row items-center gap-1 font-medium">
                      <FaPhoneAlt />
                      (65) 6259 6233
                    </Typography>
                  </a>
                  <a href={'mailto:sales@laser-ace.com'}>
                    <Typography className="flex flex-row items-center gap-1 font-medium">
                      <MdOutlineEmail />
                      sales@laser-ace.com
                    </Typography>
                  </a>
                  <a href={'https://wa.me/6597116974'}>
                    <Typography className="flex flex-row items-center gap-1 font-medium">
                      <FaWhatsapp />
                      WhatsApp
                    </Typography>
                  </a>
                </div>

                <Typography className="pt-4 font-light text-xs">Address</Typography>
                <Typography className="font-medium">
                  51 Ubi Avenue 1, #03-23 Paya Ubi Industrial Park
                </Typography>
              </div>
            </div>

            <div className="lg:px-12 px-6">
              <Typography className="text-justify">
                Established in 1997, Laser Ace Cable has been a stockist and distributor for
                Electrical Cables and its relevant accessories. We are engaged through many aspects
                in our supply of cables for various industries. These includes infrastructure and
                construction, commercial, manufacturing, data communication and residential. In
                order to stay competitive, we are committed to seek continuous improvement in both
                product quality and customer service.
              </Typography>
              {/* <div className="pt-6">
                <Link to={"/about"} className="flex items-top"><Button color="amber">Find out more</Button></Link>
              </div> */}
            </div>
          </div>
        </div>

        {/* Section - Brands */}
        <HomepageSection title="Brands" tag="brands">
          <div className="flex items-center justify-around col-span-1 lg:col-span-2 lg:mt-10 lg:px-28">
            <div className="shrink">
              <img
                src={OctLogo}
                alt="OCT logo"
                className="lg:px-16 md:px-8 px-4 py-4 object-contain shrink"
              />
            </div>
            <div className="shrink">
              <img
                src={EuroflexLogo}
                alt="Euroflex logo"
                className="lg:px-16 md:px-8 px-4 py-4 object-contain shrink"
              />
            </div>
            <div className="shrink">
              <img
                src={EurotronicLogo}
                alt="Eurotronic logo"
                className="lg:px-16 md:px-8 px-4 py-4 object-contain shrink"
              />
            </div>
          </div>
        </HomepageSection>

        {/* Section - Products */}
        <div className="flex flex-row bg-logoblue">
          <div className="w-full md:w-4/5 lg:w-5/6">
            <HomepageSection
              title="Products"
              tag="products"
              background="bg-logoblue"
              textColor="white">
              {products.length > 0 ? (
                <div className="grid lg:grid-cols-5 md:grid-cols-5 grid-cols-1 place-items-center lg:px-16 px-4 pt-6 gap-2">
                  {products.map((product) => {
                    return <ProductCategory product={product} key={product.id} />;
                  })}
                </div>
              ) : (
                <div className="w-100 text-center">Loading...</div>
              )}
              <div className="flex w-screen justify-center py-6">
                {/* <Link to={'/#products'}>
                  <Button color="amber">View all products</Button>
                </Link> */}
              </div>
            </HomepageSection>
          </div>
          <div className="w-0 md:w-1/5 lg:w-1/6 pt-4 flex">
            <img src={ProductBackground} className="self-end justify-end" />
          </div>
        </div>

        {/* Section - Projects */}
        <HomepageSection title="Projects" tag="projects">
          <div className="my-10 lg:px-40 px-10 h-[70vh]">
            <ProjectsCarousel />
          </div>
        </HomepageSection>

        {/* Section - Certifications */}
        <HomepageSection title="Certifications" tag="certifications" background="bg-secondary">
          <div className="flex flex-wrap lg:gap-16 gap-4 justify-around col-span-1 lg:col-span-2 mt-10 lg:px-28">
            {certifications}
          </div>
        </HomepageSection>

        {/* Section - Contact */}
        <HomepageSection title="Contact Us" tag="contact">
          <div className="min-h-[50vh] lg:px-12 px-6 grid grid-cols-1 lg:grid-cols-2 items-top pt-10 gap-10">
            <div className="flex flex-col text-center lg:text-left lg:pl-20">
              <Typography className="font-extrabold text-2xl mb-10 px-auto">
                Laser Ace Cable And Accessories Pte Ltd
              </Typography>

              {/* <Typography className="pt-4 font-light text-xs">Contact</Typography> */}
              <div className="flex flex-col gap-4 items-center lg:items-start">
                <Typography className="flex flex-row items-center gap-3 font-medium">
                  <RiMapPin2Fill />
                  51 Ubi Avenue 1<br />
                  #03-23 Paya Ubi Industrial Park
                  <br />
                  Singapore 408933
                </Typography>
                <a href={'tel:+6562596233'}>
                  <Typography className="flex flex-row items-center gap-3 font-medium">
                    <FaPhoneAlt />
                    (65) 6259 6233
                  </Typography>
                </a>
                <a href={'mailto:sales@laser-ace.com'}>
                  <Typography className="flex flex-row items-center gap-3 font-medium">
                    <MdOutlineEmail />
                    sales@laser-ace.com
                  </Typography>
                </a>
                <a href={'https://wa.me/6597116974'}>
                  <Typography className="flex flex-row items-center gap-3 font-medium">
                    <FaWhatsapp />
                    Click to chat on WhatsApp
                  </Typography>
                </a>
              </div>
            </div>
            <iframe
              title="Embedded Google Maps to Laser Ace"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7512529380765!2d103.89592702579853!3d1.3251549918590102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da181df22888e3%3A0xbab725218d73d142!2sLaser+Ace+Cable+%26+Accessories+Pte+Ltd!5e0!3m2!1sen!2sin!4v1512724431016"
              width="100%"
              height="840"
              style={{ border: 0, height: '100%' }}
              allowFullScreen></iframe>
          </div>
        </HomepageSection>
      </section>
    </div>
  );
};

export default Home;
