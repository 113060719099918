import React, { useEffect } from 'react';
import Hero from '../components/Hero';
import { Typography } from '@material-tailwind/react';
import { analyticsPageView } from '../utils';

import { Helmet } from 'react-helmet';

const PageNotFound = () => {
  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
    analyticsPageView(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="min-h-[85vh]">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="h-s pt-[12vh] pb-4 text-center">
        <Typography variant="h4">404</Typography>
        <Typography variant="h1">Page not found</Typography>
      </div>
      <div className="mt-10">
        <Hero />
      </div>
    </div>
  );
};

export default PageNotFound;
