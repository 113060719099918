import React, { useState } from 'react';
import { Input, Button, Typography } from '@material-tailwind/react';

import AdminEditComponent from '../components/AdminEditComponent';

const Admin = () => {
  const [userKey, setUserKey] = useState('');
  const [validKey, setValidKey] = useState(false);

  const checkValidKey = async () => {
    const res = await fetch('/api/?q=validate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        key: userKey
      })
    });
    const res_body = await res.json();
    if (res_body === 'true') {
      setValidKey(true);
    } else {
      setUserKey('');
    }
  };

  return (
    <div className="min-h-[85vh] pb-20">
      <div className="h-s pt-[12vh] pb-4 text-center">
        <Typography variant="h1">Admin</Typography>
      </div>
      <div className="flex flex-col items-center">
        {validKey ? (
          <AdminEditComponent userKey={userKey} />
        ) : (
          <div className="relative flex w-full max-w-[24rem]">
            <Input
              variant="outlined"
              label="key"
              placeholder="key"
              onChange={(event) => setUserKey(event.target.value)}
              value={userKey}
            />
            <Button
              size="sm"
              color="blue-gray"
              className="!absolute right-1 top-1 rounded"
              onClick={checkValidKey}>
              Submit
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
