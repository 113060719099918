import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import Logo from '../img/laserace_dark.png';
import { Collapse, IconButton } from '@material-tailwind/react';

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [openNav, setOpenNav] = React.useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });

  const navItems = [
    ['/#home', 'Home'],
    ['/#about', 'About'],
    ['/#products', 'Products'],
    ['/#projects', 'Projects'],
    ['/#contact', 'Contact Us']
  ];
  const navList = (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row items-center lg:gap-8">
      {navItems.map((item, index) => {
        return (
          <HashLink to={item[0]} onClick={() => setOpenNav(false)} key={index}>
            <p className="font-medium text-lg">{item[1]}</p>
          </HashLink>
        );
      })}
    </ul>
  );

  return (
    <header
      className={`${
        isActive ? 'shadow-md h-[10vh]' : 'h-[12vh]'
      } bg-white fixed w-full z-10 lg:px-8 transition-all`}>
      <div className="container mx-auto flex items-center justify-between h-full">
        <HashLink to={'/#home'} className="lg:pl-32 h-full">
          <div className="py-3 h-full">
            <img src={Logo} alt="" className="object-cover max-h-full " />
          </div>
        </HashLink>

        <div className="hidden mr-auto ml-12 lg:block">{navList}</div>

        {/* Mobile menu button */}
        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}>
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </IconButton>
      </div>
      <Collapse open={openNav} className="flex bg-white justify-center">
        {navList}
      </Collapse>
    </header>
  );
};

export default Header;
