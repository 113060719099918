import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Home from './pages/Home';
import ProductDetails from './pages/ProductDetails';
import PageNotFound from './pages/PageNotFound';
import Admin from './pages/Admin';

import Header from './components/Header';
import Footer from './components/Footer';
import FloatingButton from './components/FloatingButton';

const App = () => {
  const MEASUREMENT_ID = 'G-BLSB2PH0SJ';
  ReactGA.initialize(MEASUREMENT_ID);

  return (
    <div className="overflow-hidden">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/product/:id" element={<ProductDetails />}></Route>
          <Route path="/laserAceAdmin" element={<Admin />}></Route>
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
        <Footer />
        <FloatingButton />
      </Router>
    </div>
  );
};

export default App;
