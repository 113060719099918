import React from 'react';
import { Carousel, Button } from '@material-tailwind/react';
import { HashLink } from 'react-router-hash-link';

import Cable1 from '../img/cable_1.jpg';
import Cable2 from '../img/cable_2.jpg';
import Cable3 from '../img/cable_3.jpg';

const Hero = () => {
  return (
    <section>
      <div className="w-full lg:h-[70vh] h-[30vh]">
        <Carousel
          autoplay={true}
          transition={{ type: 'tween', duration: 0.5 }}
          autoplayDelay={3000}
          loop={true}
          navigation={() => <div></div>}
          nextArrow={() => <div></div>}
          prevArrow={() => <div></div>}>
          <div className="relative h-full w-full">
            <img
              src={Cable1}
              alt="Bundle of cables"
              className="h-full w-full object-cover object-right"
            />
            <div className="absolute inset-0 flex items-end justify-center h-full w-full">
              <div className="text-center mb-10">
                <HashLink to={'/#products'}>
                  <Button color="green" className="w-52">
                    View all products
                  </Button>
                </HashLink>
              </div>
            </div>
          </div>
          <img
            src={Cable2}
            alt="Bundle of cables"
            className="h-full w-full object-cover object-center"
          />
          <img
            src={Cable3}
            alt="Bundle of cables"
            className="h-full w-full object-cover object-right"
          />
        </Carousel>
      </div>
    </section>
  );
};

export default Hero;
