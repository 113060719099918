import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProductContext } from '../contexts/ProductContext';
import { CiCircleChevLeft } from 'react-icons/ci';
import { analyticsPageView } from '../utils';
import { Button } from '@material-tailwind/react';

const ProductDetails = () => {
  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
    analyticsPageView(window.location.pathname + window.location.search);
  }, []);

  const navigate = useNavigate();

  // get the product id from url
  const { id } = useParams();
  const { products } = useContext(ProductContext);

  //get the single product based on id
  const product = products.find((item) => {
    return item.id === id;
  });

  // if product is not found
  if (!product) {
    return <section className="h-screen flex justify-center items-center">Loading...</section>;
  }

  const { title, description, brochure_link, image_link } = product;
  document.title = `Laser Ace - ${title}`;

  return (
    <section className="pt-[12vh] min-h-[85vh] lg:pb-20 pb-10 flex flex-col items-center">
      <div className="lg:ml-[40vw] ml-10 mt-6 w-screen">
        <button onClick={() => navigate(-1)} className="flex items-center gap-1 text-lg">
          <CiCircleChevLeft />
          Back
        </button>
      </div>
      {/* image and text wrapper */}
      <div className="flex flex-col lg:flex-row items-center w-[100vw]">
        {/* image */}
        {/* <div className="lg:w-1/6 flex flex-1 justify-center items-center mb-8 lg:mb-0"> */}
        <div className="lg:w-1/2 flex items-center justify-center">
          <img
            className={image_link ? 'w-auto h-[500px] p-10' : 'w-auto h-[200px] m-32'}
            src={image_link ? image_link : '/laserace.png'}
            alt=""
          />
        </div>
        {/* text */}
        {/* <div className="lg:w-3/4 flex-1 text-center lg:text-left lg:px-16 px-4"> */}
        <div className="pl-4 pr-4 lg:w-1/2 lg:pr-10 lg:pr-0">
          <h1 className="text-[26px] font-medium mb-2 max-w-[450px] mx-auto lg:mx-0">{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: description }} className="space-y-3"></div>
          {brochure_link ? (
            <div className="pt-10">
              <a href={brochure_link} target="_blank" rel="noreferrer">
                <Button color="amber">Download Brochure</Button>
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

export default ProductDetails;
